export default {
  MAX_ATTACHMENT_SIZE: 5000000,

  s3: {
    REGION: "us-east-2",
    BUCKET: "pmm-notes-app-uploads"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://kdeqsfi2e5.execute-api.us-east-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_DnG1Xs7SO",
    APP_CLIENT_ID: "1iqaohnhoaetl2h7vd9fl5stl4",
    IDENTITY_POOL_ID: "us-east-2:108b551c-91ee-453b-aebd-75eec9658d49"
  }
};
